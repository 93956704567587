<template>
  <VRow
    column
    fill-height
  >
    <VCol class="py-0">
      <VSnackbar
        :timeout="0"
        :multi-line="true"
        top
        right
        color="dark"
        v-model="snackbar"
      >
        Continue where you left off?
        <VBtn
          text
          color="info"
          @click="onContinueFromLast"
        >
          Yes
          <VIcon>forward</VIcon>
        </VBtn>
        <VBtn
          text
          color="default"
          @click="onCancelContinueFromLast"
        >
          No
          <VIcon>close</VIcon>
        </VBtn>
      </VSnackbar>
      <Cards
        v-model="currentIndex"
        ref="cards"
        :assessment="model"
        @title="onUpdateTitle"
        @currentIndex="onUpdateCurrentIndex"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import Cards from "./Cards";
import HasAssessmentsMixin from "@/mixins/HasAssessments";

export default {
  name: "Presentation",
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [ViewMixin, HasAssessmentsMixin],
  components: { Cards },
  data() {
    return {
      dialogs: {
        cards: true,
      },
      title: "",
      currentIndex: 0,
      lastCardIndex: 0,
      currentCard: {},
      modelType: "Presentation",
      hasContinuedFromLast: false,
      snackbar: false,
      hasLoadedCardFromUrl: false,
    };
  },
  computed: {
    canContinueFromLast() {
      return (
        this.lastCardIndex > 0 &&
        this.currentIndex !== this.lastCardIndex &&
        this.hasContinuedFromLast === false
      );
    },
  },
  watch: {
    canContinueFromLast: function (value) {
      if (value) {
        this.snackbar = true;
      }
    },
    modelHasId: function (value) {
      if (value) {
        const allCards = this.model.all_cards || this.model.cards || [];
        if (!this.hasLoadedCardFromUrl && this.$route.params.cardId) {
          this.currentIndex = allCards.findIndex(
            (card) => card.id === parseInt(this.$route.params.cardId)
          );
        } else {
          this.lastCardIndex =
            this.model.result_for_user instanceof Object
              ? allCards.findIndex(
                  (card) =>
                    card.id ===
                    parseInt(this.model.result_for_user.current_card_id)
                )
              : 0;
        }
        this.hasLoadedCardFromUrl = true;
      }
    },
  },
  methods: {
    onGet(id) {
      return this.onGetAssessment(id)
        .then((result) => {
          console.log("Lesson", result);
          // Prevent students from taking lessons with prerequisites
          if (this.$isUserStudent && !result.user_can_take) {
            this.navigateTo({ name: "lesson.index" });
            this.$bus.$emit(
              "notificationError",
              `You can't take this ${this.featureName(
                "lesson",
                "singularize"
              )} yet. Please see the prerequisite information for: <strong>${
                result.title
              }</strong>`
            );
          }
          return result;
        })
        .catch((error) => {
          if (error.code === 404) {
            this.navigateTo({ name: "lesson.index" });
            this.$bus.$emit(
              "notificationError",
              "The requested lesson doesn't exist"
            );
          }
        });
    },
    closeAndReturn() {
      this.dialogs.cards = !this.dialogs.cards;
      this.$router.go(-1);
    },
    onUpdateCurrentIndex(value) {
      this.currentIndex = value;
    },
    onContinueFromLast() {
      this.$refs.cards.setCurrentIndex(this.lastCardIndex, true);
      this.hasContinuedFromLast = true;
      this.snackbar = false;
    },
    onCancelContinueFromLast() {
      this.$refs.cards.setCurrentIndex(-1, true);
      this.hasContinuedFromLast = true;
      this.snackbar = false;
    },
  },
};
</script>

